<template>
  <div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, nextTick, ref } from "vue";
import { useRoute, useAsyncData, useRequestHeaders } from "#imports";
import useClientTypeStore from "~/stores/clientType";
import useConfigStore from "~/stores/config";
import toast, { initToast } from "~/lib/alert";

const configStore = useConfigStore();
const clientTypeStore = useClientTypeStore();
const route = useRoute();
function stylingHtmlElementHybrid() {
  const { lang } = route.params;
  if (lang === "hybrid" && !clientTypeStore.isDesktop) {
    const styleElement = ref(document.documentElement.classList);
    styleElement.value.add("hide-scrollbar");
  }
}
async function determineClientType() {
  const headers = useRequestHeaders();
  const userAgent = headers["user-agent"];
  await clientTypeStore.determineClientType(userAgent);
}

async function getConfig() {
  const { data } = await useAsyncData("getConfig", () =>
    configStore.getConfig()
  );
  if (data.value) {
    const { success, message } = data.value;
    if (!success) {
      toast.error(message);
    }
  }
}

await determineClientType();
await getConfig();

onMounted(async () => {
  initToast({
    x: "center",
    y: "top",
  });
  stylingHtmlElementHybrid();
  configStore.getConfig();
  const {
    setupAndroidBridge,
    setAndroidBridgeInstance,
    setupIosBridge,
    setIosBridgeInstance,
    setupWebBridge,
    setWebBridgeInstance,
    setupPlatformBridge,
  } = await import("~/composables/hybrid/platformBridge");
  setupPlatformBridge({ isWebClient: configStore.isWebClient });
  nextTick(() => {
    setupWebBridge((webBridge: Window) => {
      setWebBridgeInstance(webBridge);
    });
    setupAndroidBridge((androidBridge: any) => {
      androidBridge.init();
      setAndroidBridgeInstance(androidBridge);
    });
    setupIosBridge((iosBridge: any) => {
      setIosBridgeInstance(iosBridge);
    });
  });
});
</script>

<style>
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
/* For IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bg-overlay-gray {
  background: linear-gradient(90deg, #ffffff 54.72%, #ededed 64.13%);
}
</style>
